import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/expertise.jpg'
import pic02 from '../assets/images/work.jpg'
import pic03 from '../assets/images/professional-recommendations.jpg'
import pic04 from '../assets/images/contact-me.jpg'
import pic06 from '../assets/images/jonathan-agoot-speaking-engagements.jpg'

class HomeIndex extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout>
				<div>
					<Helmet>
						<title>{siteTitle}</title>
						<meta name="description" content={siteDescription} />
					</Helmet>

					<Banner />

					<div id="main">
						<section id="one" className="tiles">
							<article style={{
								backgroundImage: `url(${pic02})`
							}}>
								<header className="major">
									<h3>Portfolio</h3>
									<p>Adventures in the Digtal World</p>
								</header>
								<Link to="/portfolio" title="Jonathan Agoot - Portfolio" className="link primary"></Link>
							</article>
							<article style={{
								backgroundImage: `url(${pic01})`
							}}>
								<header className="major">
									<h3>Expertise</h3>
									<p>Partner With Me</p>
								</header>
								<Link to="/expertise" title="Jonathan Agoot - Expertise" className="link primary"></Link>
							</article>
							<article style={{
								backgroundImage: `url(${pic03})`
							}}>
								<header className="major">
									<h3>Professional Recommendations</h3>
									<p>Testimonials</p>
								</header>
								<Link to="/recommendations" title="Jonathan Agoot - Professional Recommendations" className="link primary"></Link>
							</article>
							<article style={{
								backgroundImage: `url(${pic04})`
							}}>
								<header className="major">
									<h3>Contact Me</h3>
									<p>Let's get the conversation started</p>
								</header>
								<Link title="Jonathan Agoot - Contact Me" to="/contact-me" className="link primary"></Link>
							</article>
							<article style={{ backgroundImage: `url(${pic06})` }}>
								<header className="major">
									<h3>Speaking Engagements</h3>
									<p>Book me for your next event.</p>
								</header>
								<Link to="/speaking-engagements" className="link primary"></Link>
							</article>
						</section>
					</div>
				</div>
			</Layout>
		)
	}
}

export default HomeIndex

export const pageQuery = graphql`
	query PageQuery {
		site {
			siteMetadata {
				title
				description
			}
		}
	}
`
