import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import pic01 from '../../assets/images/jonathan-agoot-serverless-computing.jpg'
import pic02 from '../../assets/images/jonathan-agoot-machine-learning-aws-near-serverless-architecure.jpg'
import pic03 from '../../assets/images/jonathan-agoot-machine-learning-algorithm-development-overview.jpg'
import pic04 from '../../assets/images/jonathan-agoot-machine-learning-dynamically-assembled-ads.jpg'
import pic05 from '../../assets/images/jonathan-agoot-machine-learning-prototype-roadmap.jpg'

const Generic = (props) => (
	<Layout>
		<div>
			<Helmet>
				<title>Serverless Computing and Machine Learning | Jonathan Agoot</title>
				<meta name="description" content="Jonathan Agoot - Serverless Computing and Machine Learning" />
			</Helmet>
			<div id="main" className="alt">
				<section id="one" className="tiles">
					<div className="inner">
						<header className="major">
							<h1>Serverless Computing<br />and Machine Learning</h1>
						</header>
						<hr />
						<header className="major">
							<h3>Preface</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-6">
                                <p>When I joined Thermo Fisher Scientific in 2011, I had started a conversion rate optimization(CRO) program to get better conversions for digital marketing campaigns and our eCommerce website with over a million web pages comprised of four major platforms. We've learned a lot about customer buying behavior and of course that translated into consistent solid eBusiness revenue growth and organizational knowledge.</p>
                                <p>When I joined Thermo Fisher Scientific in 2011, I had started a conversion rate optimization(CRO) program to get better conversions for digital marketing campaigns and our eCommerce website with over a million web pages comprised of four major platforms. We've learned a lot about customer buying behavior and of course that translated into consistent solid eBusiness revenue growth and organizational knowledge.</p>
							</div>
							<div className="col-6">
								<span className="image fit"><img src={pic01} alt="Serverless Computing and Machine Learning" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Find Early Adopters</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>I've learned that in an enterprise environment, ideas need to be marketed and sold for further support, which I really enjoyed doing (it reminded me of my tech startup days)! And because of the various experiments that I've conducted over the years, I had a lot of proof but that was not enough. However, if I had a working automated prototype, that would be even better!</p>
								<p>Before the creation of a near-serverless prototype, I created a very small prototype on my laptop using real data that constituted of:</p>
								<ul>
									<li>Any data related to identifying the customer</li>
									<li>Clicks</li>
									<li>Visited pages</li>
									<li>SKU’s added to cart</li>
									<li>SKU’s they’ve copied</li>
									<li>Downloaded product manuals (which aids in conversion by the way...)</li>
									<li>Form submits</li>
								</ul>
								<p>I used PredictionIO (months before Salesforce acquired it) because it was a free and open source machine learning engine. Then I output the recommendations in JSON format which could be used to create dynamically assembled ads with Javascript. At the time we had a creative department that was manually creating the ads, a time and resource intensive process that slowed our pace of execution.</p>
								<p>I demoed the prototype to my team and leadership. About two weeks after the demo, I earned more support.</p>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Knowing My Customers</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>At the time, product recommendations were rendered manually by former bench scientists that work on the business side and also sales and marketing analysts. They would pick out products based on a variety of methods such as previous purchasing behavior, statistics, and scientific workflows that follow the central dogma of molecular biology. Not one method would prevail but rather a contextual use instead.</p>
								<p>With machine learning, it would be possible to combine those methods in the form of highly customized algorithms to bring personalized near real-time automated recommendations to customers and improve their shopping experience.</p>
								<p>For automated product recommendations to be possible, the behaviors of the customer must be repeatable and actionable. From my previous research, there are repeatable shopping patterns but not everyone has the same pattern. And not every customer is a B2C customer. In our B2B customer type, we have more than 5 subtypes. And because we do business globally, not all customers transact through the website alone. I had to define a limited scope where I’m not boiling the whole ocean, but rather a very targeted prototype for a specific set of customers.</p>
								<p>Here’s the targeting criteria of the prototype:</p>
								<ul>
									<li>North America market only</li>
									<li>B2C</li>
									<li>Customers who are in a logged in state and visit the website more than once per week for almost every month of the year.</li>
									<li>Take into account for seasonal behaviors: universities and life sciences companies purchase less during summer and winter months.</li>
									<li>Prototype is placed at the most visible and highly trafficked web pages where I can create specific targeting criteria to serve up recommendations that were contextually relevant to the information architecture of the website.</li>
								</ul>
								<p>How my customers shop:</p>
								<ul>
									<li>Most of the time, scientists put the shopping list together. They browse the website, click on merchandising ads, download manuals, open emails, fill out forms to view webinars and eBooks, etc.</li>
									<li>Some scientists add to cart. Some scientists copy and paste into an email or spreadsheet and forward to their purchaser or principal investigator.</li>
									<li>The purchaser or principal investigator conducts the transactions through procurement systems or use a credit card at the website. There’s also budget considerations so scientists may not get everything they’ve asked for.</li>
									<li>Because there’s a purchaser or principal investigator doing the buying, it’s very difficult to attribute the sale to the end user, the scientist.</li>
									<li>Procurement systems leave a very small data footprint and don’t play well in a digital marketing context.</li>
								</ul>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Building The Product<br /> Recommendations Engine</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>I decided that the prototype needed to be cloud native so we can develop faster and not worry about migration challenges down the road. Naturally, AWS is the clear solution.</p>
								<p>Also, it needed to also be near serverless as possible for three primary reasons:</p>
								<ol>
									<li>Reduced operating costs, we pay for only what we use.</li>
									<li>Allows my team to focus on perfecting product recommendations and less on managing infrastructure.</li>
									<li>Take advantage of the evolving AWS ecosystem of services. Also, utilize AWS enterprise support when needed.</li>
								</ol>
								<p>In 2015, serverless machine learning engines did not exist on AWS yet, so I chose PredictionIO because it was:</p>
								<ol>
									<li>Free and open source.</li>
									<li>Had generic algorithms for recommendations we could learn from and modify.</li>
									<li>A very active community of developers with documentation.</li>
									<li>Ease of use and implementation.</li>
								</ol>
								<p>PredictionIO was the only part of the engine where I needed to use an EC2 M4.xlarge.</p>
								<p>The alternatives at the time were too expensive because they ran on traditional servers and were closed source. This translated to “we had to pay for customizing algorithms and rely on professional services instead”.</p>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Simplified AWS<br />Near-Serverless Architecture</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>We pieced together our engine and learned a great deal along the way. It didn’t look like this at the beginning. We failed a lot but we learned a great deal more!</p>
								<span className="image fit"><img src={pic02} alt="Simplified AWS Near-Serverless Architecture" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Prototype Roadmap</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>I was able to minimize my risk by bootstrapping the project and developing with low cost solutions that had very little to no barrier-to-entry. At every stage of prototyping, we began to understand the “ins and outs” of implementing a machine learning platform, testing recommendations in lower environments before production, and building on AWS (which is a joy).</p>
								<span className="image fit"><img src={pic05} alt="" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Algorithm Development</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>I treated algorithm development just like product management.</p>
								<span className="image fit"><img src={pic03} alt="Jonathan Agoot - Algorithm Development" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Preparing for Ad Delivery<br /> and Ad Templates</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>Because our onsite digital ads were being manually produced at the time, this became a bottleneck for a recommendation engine. But this could be solved by combing 1) recommendations data stream paired with SKU and userID 2) a database containing SKU, product page URL, image URL 3) a backend process to assemble these recommendations in a cache or assembled in real-time during first paint.</p>
								<span className="image fit"><img src={pic04} alt="Jonathan Agoot - Preparing for Ad Delivery and Ad Templates" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Project Impact</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-12">
								<p>Our first implementation of machine learning and developing in cloud in a small prototype context helped the company understand the complexities in a large global enterprise setting. We started small and kept iterating until we achieved an operational near real-time automated product recommendation engine. I would definitely would like to do this again but with 100% serverless architecture!</p>
								<p>Although the project came to an end, it spawned even more eCommerce machine learning projects, dedicated funding, and continued support from leadership.</p>
								<p>"Accelerating discovery, enhancing life."</p>
							</div>
						</div>
						<hr />
					</div>
				</section>
			</div>
		</div>
	</Layout >
)

export default Generic
