import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic08 from '../assets/images/jonathan-agoot.jpg'

const cv = (props) => (
	<Layout>
		<div>
			<Helmet>
				<title>About Me | Jonathan Agoot</title>
				<meta name="description" content="About Jonthan Agoot" />
			</Helmet>
			<div id="main">
				<section id="banner" className="style2">
					<div className="inner">
						<header className="major">
							<h1>About Me</h1>
						</header>
						<div className="content">
							<p>My Background</p>
						</div>
					</div>
				</section>
				<section id="zero">
					<div className="inner">
						<img src={pic08} className="image right" alt="Jonathan Agoot - Digital Innovation and Emerging Technology Strategist" />
						<section>
							<h2>Hello, I'm Jonathan Agoot and I wear many hats.</h2>
							<p>There's that saying about <i>"Jack of all trades, master of none."</i> But most don't know the last part of that saying... <i>"though oftentimes better than master of one."</i> <u>So what am I better at?</u> Seeing the big picture so I can solve your problems.</p>
							<ol>
								<li><b>I've specialized in digital innovation for years.</b> I'm constantly engorging myself at the buffet of information (sounds grotesque eh?) and frequently experimenting at the same time. This is how I make sense of the big picture so I can provide sound advice and consultation to my clients.</li>
								<li><b>I've sat on six sides of the table (yes they exist). </b> As a tech entrepreneur, business developer, marketer, analyst, and developer, I leave no stone unturned in pursuit of the truth.</li>
								<li><b>I'm a problem solver. Finding solutions comes naturally for me.</b> And that's especially true with problems that are worth solving. <b>Waking up in the middle of the night? Not concentrating in your meetings?</b> <a href="/contact-me">Contact me</a>.</li>
								<li><b>I'm committed to you.</b> From the moment we make a agreement, you will get to know how digital innovation will transform your business. Stagnating growth? Stuck in group think? Confused about emerging technology? Is the pace of digital technology leaving your business behind? That's what I'm here for! <a href="/contact-me">Contact me</a>.</li>
							</ol>
						</section>
					</div>
				</section>
				{/*<section id="one">
	  <div className="inner">
		<h2>Emerging Technology and Digital Innovation Strategist</h2>
		<h3>Thermo Fisher Scientific, Corporate Global eBusiness, Innovation Center of Excellence</h3>
		<h4>2016 - 2017</h4>
	  </div>
	</section>
	<section id="two">
	  <div className="inner">
		<h2>Marketing Technologist and Conversion Rate Optimization Program Director</h2>
		<h3>Thermo Fisher Scientific, eBusiness, Life Sciences Group, Integrated Marketing Management</h3>
		<h4>2015 - 2016</h4>
	  </div>
	</section>
	<section id="threehalf">
	  <div className="inner">
		<h2>Lead Generation Manager / Web Operations Regional Manager</h2>
		<h3>Thermo Fisher Scientific, eBusiness, Life Sciences Group</h3>
		<h4>2013 - 2015</h4>
	  </div>
	</section>
	<section id="three">
	  <div className="inner">
		<h2>Lead Generation Manager / Web Developer</h2>
		<h3>Thermo Fisher Scientific, eBusiness, Life Sciences Group</h3>
		<h4>2011 - 2013</h4>
	  </div>
	</section>
	<section id="four">
	  <div className="inner">
		<h2>Head of Marketing</h2>
		<h3>CrossFit 760, LLC</h3>
		<h4>September 2013 - January 2016</h4>
	  </div>
	</section>
	<section id="five">
	  <div className="inner">
		<h2>Founder and CEO</h2>
		<h3>Synergist CG</h3>
		<h4>2005 - 2011</h4>
		<p>Web3D and WebVR eCommerce Platform</p>
	  </div>
	</section>
	<section id="nine">
	  <div className="inner">
		<h2>Education</h2>
		<section>
		  <h3>University of California-Irvine</h3>
		  <p>Predictive Analytics, Digital Marketing Course Development, and Marketing Research</p>
		</section>
		<section>
		  <h3>The Art Institute of California-San Diego</h3>
		  <p>Bachelor Degree in Digital Communications and Media Arts</p>
		</section>
	  </div>
	</section>
	<section id="ten">
	  <div className="inner">
		<h2>Achievements</h2>
		<h3>2016 Thermo Fisher Team Award: Integrated Marketing Management and Innovation</h3>
		<h3>2016 Thermo Fisher Ace Award: Building Customer Allegiance</h3>
		<h3>2012 Thermo Fisher Marketing Excellence Award: Impact</h3>
	  </div>
	</section>*/}
			</div>
		</div>
	</Layout>
)

export default cv
